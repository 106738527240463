body{
   margin: auto;  
} 


@media (max-width: 400px) {
    .page_login  {
        margin: 50% auto;
        width: 80%
  }
  .form{
      width: 100%;
  }
}
.errorMessage1 {
    color: red;
    position:'relative';
    margin-top: 7px;
    height: 14px;
    font-size: 10pt;

}
.passMessage1 {
    color: green;
    position:'relative';
    margin-top:7px;
    height: 14px;
    font-size: 10pt;
}
.meesageValidate1{
    position:'absolute'
}


.msg1 {
    margin-top: 0px;
}