@font-face {
  font-family: inter;
  src: url(../font/Inter-VariableFont_slnt\,wght.ttf);
}
.user-image {
  width: 100%;
  }
  .user-info{
    border-left: 2px dotted;
    margin-bottom: 50px;
    margin-top: -40px;
    padding-left: 24px;
    position: relative;
    z-index: 1000;
  }
 .user-info h1{
  font-size: 17px;
  font-weight: 700;
  font-family:'inter' ;
  margin: 0px;
 }
 .user-info h2{
  font-size: 14px;
  font-weight: 500;
  font-family:'inter' ;
  margin: 8px 0px;
 }
 .user-info p{
  font-size: 14px;
  font-weight: 500;
  font-family:'inter' ;
  color: #959595;
  margin: 0px;
 }
 
 .main-user-info{
  padding: 0px 28px;
 }
 .user-linkes{
  display: flex;
  row-gap: 40px;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;

 }
  .user-linkes a {
    width: 25%; 
  }
  .footer{
    height: 104px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* background-color: #4A4A4A; */
    padding:  16px;
    /* margin-top: 90px; */
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYkAAAB9CAYAAABXqZ8GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASgSURBVHgB7d1NbhtlGMDx5x07XecIuQG5ARZqkSoWhBO07Ni0DSconKCVQGxbbkBXRW1FwgkIJyBHyLJqYg8eO04d12+aD9vxzPx+Ur7sLLL763mfmUkEAAAAAAAAANym3b3eZgDAHEW8724HAMxR9FOIBABzFUWRvggAmKMIkwQAGcXwY9vyGoB5itHn442tAIAZo0j0o+/ICYBPjCKRIokEAJ8YRyIVXwYAzChOv24FAMyYRGLzh9e9rQCAKZNIxEan6AUATDmLhOU1ALM+RiIVHs8BwDnF1PcmCQDOmY6E5TUA50xHIrrFHdMEAGfORaJI/V4AwKlzkbC8BmBaMfOzx4YDcGY2Epv+5zUAE7ORiEERvQCAmBOJVCRPhAVgpJjzWs9eAoDKvEhE/7jYCQBab24kitR5EAC0XpF53aWwAGQjsenICYAi+4YjJ4DWKy54z1VOAC13USRi8KG7GwC01oWRSCl9GwC01oWRiBTbu6/v9gKAVio+9wtlNz0NAFrps5EIC2yA1rpMJCywAVrqUpFIRXpimgBon0tFYmjTNAHQPpeNhGkCoIUuHYkwTQC0zlUiYZoAaJkrRWJoszzeeBYAtMJVI1Hdhf3QXdgA7XD1SIS7sAHa4lqRGOo9+vOuJTZAw103ElF00lNLbIBmu3Ykolpin9xx7ATQYDeJxFC5a4kN0Fw3jMQwE530wrETQDPdOBKRYqs86b4IABrn5pEYSTuudgJongVF4vRqp9e9rQCgMRYWiaiudups7NlPADTHIiNhPwHQMIuNxEjaefz2rvsnABpgCZGoBor00+N3Xz8IAGptKZGopLJ8vvvm3nYAUFtLi0RUi+wUe654AqivZUaiMr7iSSgAamnZkRhf8SQUALW0/EhUhAKgllYTiYpQANTO6iJREQqAWlltJCpCAVAbq49EZRIK91EArLXbiUSlCkWKfzxiHGB93V4kJn9AJz3zrCeA9ZSevL1Xxloo/0gnJz8+v79/GACshVufJD5KOxbaAOtljSIR4z1Fd+M/x08A62G9InGqetT4kzf3XpgqAG7XWkZiJMXD6vjp0ZuvHgYAt2KNFtcXKONl6h//bKkNsFrrO0lMm0wV7qkAWKl6TBLTyjjs98vvf73/bj8AWKp6TBLTUmx1umnPYhtg+eo3ScyyrwBYmvpHYkIsABauOZGYEAuAhWleJM6U+4Ny8PsvX//1MgC4lgZH4lQZh8PP+6YLgKtrfiTOGU8Xnf5gXzAAPq9lkZg2DEbEq/6HtP/bN28PAoBPtDgSU06PpPrl4FV30D8wZQCMicR8w8miPBxOGn+XJ3HQfX9y8Py7/aMAaBmRuLwqHEdlv/y37KTDKh6DQTq68+H4UECAphKJRUnDI6syjqqQnL1WlocBUGPdYDHK2Bp/kz6+llIA1Fn9HvAHwMqIBABZIgFAlkgAkCUSAGSJBABZIgFAlkgAkCUSAGSJBABZIgFAlkgAkCUSAGSJBABZIgFAlkgAkCUSAGSJBABZIgFAlkgAkCUSAGSJBABZIgFAlkgAkCUSAGSJBABZIgFAlkgAkCUSAGSJBABZIgFA1v+zDzhNs8DD6gAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-size: 100%;
    justify-content: center;

  }
  .footer p{
    font-size: 12px;
  color: #fff;
  }
  .save-button{
    width: 227px;
    height: 50px;
    position: fixed;
    bottom: 50px;
    border-radius: 8px;
    border: unset;
    background: #6FBB45;
    font-size: 18px;
    font-weight: 700;
    left: 50%;
    color: #fff;
    transform: translate(-50%, 0%);
    cursor: pointer;
    z-index: 1000;
}
/* .container{
  background: #E2E2E2;
} */
@keyframes shadowAnimation {
  0% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Start shadow */
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7); /* Midway shadow */
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* End shadow */
  }
}

/* Apply the animation to the button */
.save-button {
  animation: shadowAnimation 1s ease-in-out infinite; /* Adjust duration and timing function as needed */
  /* Additional button styles */
}
.images-top{
  position: relative;
}
.img-color{
  background: #fff;
  border-top-right-radius: 86px;
  bottom: -45px;
  left: 0;
  position: absolute;
  top: auto;
  width: 100%;
  z-index: 11;
}
.upload-section{
  display: flex;
    flex-direction: column;
    border: 1px solid #6FBB45;
    align-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px;
    color:#6FBB45
}
.upload-section p{
  color:#6FBB45 !important;
  margin-top: 0px;
}
.upload-section img{
  width: 35px;
}
.pointer{
  cursor: pointer;
}
.main-image-link{
  text-decoration: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}
.main-image-link span{
  color: #7C7C7C;
  font-size: 12px;
}
.gellary-of-user ,.makeStyles-root-6{
  margin-top: 15px;
  max-width: 100% !important;
  width: 100% !important;
  max-height: 350px !important;
  border-radius: 20px !important;
}
.gellary-of-user h2{
  color: #6FBB45;

}
.gellary-of-user img{
  border: 25px;
}
.width-of-page{
  max-width: 450px !important;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #bfadad;
}
.image-link{
  height: 65px;
  width: 65px;
  /* background-color: #6FBB45; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.gellary-of-user .jss1 ,.gellary-of-user .jss5 {
  height: 300px;
  width: 100% !important;
}
.gellary-of-user .jss12{
  opacity: 1 !important;
}
.user-info-new{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.user-info-new img{
  border-radius: 10px;
}
.logo-image{
  width: 100px;
  height: 100px;
}
.pdf-link{
  display: flex;
  gap: 8px !important;
  align-items: center;
}
.main-pdf-link{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  padding-left: 20px;
}
  
  